import PostTitle from '../components/post-title';
import Date from "./date";
import React from 'react';

export default function PostHeader({title, date, category}) {
    const getText = () => {
        if (category.slug === 'notices') {
            return title;
        } else {
            return <span>On <Date dateString={date}/>, the Financial Conduct Authority issued a warning regarding {title}</span>;
        }
    }
    return (
        <PostTitle>{getText()}</PostTitle>
    )
}
