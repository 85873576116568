import Date from "./date";
import React from 'react';

export default function PostBody({content, title, date, firmName, documentUrl, category, subTitle}) {

    content = content.replace(/&lt;/g, '<');
    content = content.replace(/&gt;/g, '>');
    content = content.replace(/>&quot;</g, '><');
    content = content.replace(/&quot;/g, '"');
    /*content = content.replace(/position:absolute;/g, '');
    content = content.replace(/<[^/>][^>]*><\/[^>]+>/g, '');*/

    return (
        <div className="mx-auto">
            <div className="text-gray-700 pt-5 border-t border-accent-2">
                {category.slug === 'notices' && <div className="text-xl font-semibold mb-5">On <Date dateString={date}/>, the Financial Conduct Authority
                    issued a {title} to {firmName}</div>}
                {category.slug === 'warnings' && <><div className="text-xl font-semibold mb-5">In its notification, the FCA said "{subTitle}".</div><p>The FCA went on to note that</p></>}
                <div style={{position: "relative"}} dangerouslySetInnerHTML={{__html: content}}/>
                <div className={"text-sm md:text-lg text-center md:text-left"}>
                    <a className={"text-blue-600"} href={documentUrl} target={'_blank'} rel="noreferrer">Click here to view the FCA's original notice</a>
                </div>
            </div>
        </div>
    );
}
