import React from 'react';
import Layout from "../components/layout";
import Container from "../components/container";
import Header from "../components/header";
import PostHeader from "../components/post-header";
import PostBody from "../components/post-body";
import SectionSeparator from "../components/section-separator";
import {format, parseISO} from "date-fns";
import {Helmet} from "react-helmet/es/Helmet";

const Article = (props) => {
    const article = props.pageContext.article;
    const seoTitle = article.category.slug === 'notices' ? `FCA issues a ${article.title} to ${article.firmName}` : `FCA issued a warning regarding the ${article.title}`;
    const seoDescription = article.category.slug === 'notices' ?
        `On ${format(parseISO(article.publishingDate), 'LLLL d, yyyy')}, the Financial Conduct Authority issued a ${article.title} to ${article.firmName}` :
        `On ${format(parseISO(article.publishingDate), 'LLLL d, yyyy')}, the Financial Conduct Authority issued warning regarding ${article.title}`;
    return (
        <Layout>
            <Helmet>
                <title>{seoTitle}</title>
                <meta property="og:locale" content="en_GB"/>
                <meta property="og:type" content="article"/>
                <meta property="og:title" content={seoTitle}/>
                <meta property="og:description" content={seoDescription} key="description" />
                <meta property="og:url" content={`https://www.regulatorwarnings.com/${props.pageContext.url}`}/>
                <meta name="description" content={seoDescription} />
            </Helmet>
            <Container>
                <Header category={props.pageContext.category}/>
                <article className={"lg:mx-20"}>
                    <PostHeader
                        title={article.title}
                        category={article.category}
                        date={article.publishingDate}
                    />
                    <PostBody
                        content={article.content}
                        title={article.title}
                        date={article.publishingDate}
                        documentUrl={article.documentUrl}
                        firmName={article.firmName}
                        category={article.category}
                        subTitle={article.subTitle}
                    />
                </article>
                <SectionSeparator/>
            </Container>
        </Layout>
    );
}

export default Article;
